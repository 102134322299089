.App {
  font-family: sans-serif;
  text-align: center;
}

.file-upload {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;
}

.file-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.file-name {
  background-color: #6c757d;
  padding: 8px;
  color: white;
  border-radius: 4px;
}

.file-progress {
  width: 100px;
  height: 12px;
  background-color: #d8d8d8;
  border-radius: 6px;
  margin-left: 12px;
  position: relative;
}

.progress-bar {
  height: 100%;
  border-radius: 6px;
  color: white;
  font-size: 10px;
  text-align: center;
  line-height: 12px;
}

.file-actions {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
label {
  font-size: 14px;
    line-height: 17px;
    letter-spacing: 1.2px;
}
.file-error{
  margin-top: 20px;
  color: red;
}
.remove-file,
.re-upload-file {
  cursor: pointer;
  font-style: normal;
  margin-left: 8px;
  font-size: 18px;
  color: #333;
}

.file-upload-input {
  display: block;
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.0625rem + 2px);
  margin: 0;
  opacity: 0;
}


.label-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  margin-bottom: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.0625rem + 12px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #3e515b;
  background-color: #fff;
  border: 1px solid #c2cfd6;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.0625rem + 12px) - 1px* 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #3e515b;
    content: "Browse";
    background-color: #f0f3f5;
    border-left: 1px solid #c2cfd6;
  }

}

.required {
  color: red;
  font-size: 16px;
  margin-left: 5px;

}