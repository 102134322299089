.Navbar{
  background-color:none;
  /* height: 80px; */
  display: flex;
  justify-content: flex-start;
  width: 260px;
  align-items: center;
  overflow-y: scroll;
  overflow-y: hidden;
  border-radius: 50px 20px;
}

.menu-bars{
 margin-left: 2 rem;
 font-size: 2 rem;
 background: none; 
 text-decoration: none;
}

.Nav-Menu{
  background-color: #060b26;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  position: fixed;
  top: 0px;
  left: 0px;
  /* left: -100%; */
  transition: 850ms;
  border-radius: 5px;
}
.Nav-Menu::-webkit-scrollbar {
  display: none;
}
.Nav-Menu.active{
  left: 0;
  transition: 350ms;
}

.nav-text{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a{
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  border-radius: 4px;
}

.nav-text a:hover{
  background-color: #1a83ff ;
}

.Nav-Menu-Items{
  width: 100%;
}

.navbar-toggle{
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 10px;
}

.Menu-Options{
  color:#f5f5f5;
  text-decoration: none;
  width: 85%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 16px;
  border-radius: 4px;
  margin-left: 20px;
  font-size: 20px;
  background-color: none;
  margin-top:20px; 
  border-radius: 50px 20px;
  /* background-color: rgb(42, 14, 78); */
}

.Sidebar{
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 16px;
  border-top: solid 1px;
  border-radius: 50px 20px;
  /* border-bottom: solid 1px; */
}

.Sidebar:hover {
  background: #252831;
  border-left: 4px solid #632ce4;
  cursor: pointer;
}

.Dropdown{
  background: #3d465f;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fdfafa;
  font-size: 18px;

  border-radius: 5px 5px;
}

.Dropdown:hover{
  background: #6328ec;
  cursor: pointer;
  color: #f5f5f5;
}