.tab-manager {
  display: flex;
  padding-bottom: 5px;
}
.tab {
  border-radius: 5px;
  text-align: left;
  cursor: pointer;
  padding: 12px 20px;
  margin: 20px 5px;
  font-size: 16px;
  letter-spacing: 0.5px;
  user-select: none;
  background-color: #dddbdb;
}

.selected-tab {
  background-color: rgb(151, 148, 148);
  color: black;
}
