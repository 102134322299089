.form-content{
    display: flex;
}
.form-item{
    padding: 20px;
}
.eligible{
    color: green;
}
sup{
    top: -3px;
}
.button{
    height: 64px;
    padding: 20px;
    background-color: rgb(76, 135, 92);
    color: white;
    border: 0;
    margin-left: 20px;
}
.jobId{
    width: 30%;
    padding: 20px;
}
.removeFile{
    position: absolute;
    bottom: 20px;
    right: 0;
    font-weight: 700;
  
    cursor: pointer;
}
  
  
  

  

  
  .file-actions {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
  

  