
* {
box-sizing: border-box;
margin: 0;
padding: 0;
font-family: 'Lato', sans-serif;
/* background-color: rgb(145, 164, 170); */
}

.content-body{
margin-left: 252px;
top: 0;
background-color:rgb(197, 205, 226);
/* height: 100vh; */
min-height: 100vh;
border-radius: 5px;
padding: 30px;
}

[data-loader]{
cursor: progress;
position: relative;
}

[data-loader]::before{
content: '';
position: absolute;
z-index: 10;
display: block;
width: 100%;
height: 100%;
background-color: rgba(255, 255, 255, 0.8);
}


/* Table using divs */
.divTable{
display: table;
width: 100%;
border: 1px solid rgb(26, 6, 6);
border-collapse: collapse;
}
.divTableRow {
display: table-row;
}

.divTableCell, .divTableHead {
border: 1px solid rgb(26, 19, 19);
display: table-cell;
padding: 8px;
vertical-align: middle;
}
.divTableHeading {
display: table-header-group;
font-weight: bold;
}
.divTableFoot {
background-color: #ddd;
display: table-footer-group;
font-weight: bold;
}
.divTableBody {
display: table-row-group;
}
.divTableHover > .divTableBody > .divTableRow:hover {
background-color: #f5f5f5; }



.modal-open {
overflow: hidden; }

/* line 16, airtel/assets/scss/standard/_modals.scss */
.modal {
display: none;
overflow: hidden;
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 1050;
-webkit-overflow-scrolling: touch;
outline: 0; }
/* line 32, airtel/assets/scss/standard/_modals.scss */
.modal.fade .modal-dialog {
-webkit-transform: translate(0, -25%);
-ms-transform: translate(0, -25%);
-o-transform: translate(0, -25%);
transform: translate(0, -25%);
-webkit-transition: -webkit-transform 0.3s ease-out;
-moz-transition: -moz-transform 0.3s ease-out;
-o-transition: -o-transform 0.3s ease-out;
transition: transform 0.3s ease-out; }
/* line 36, airtel/assets/scss/standard/_modals.scss */
.modal.in .modal-dialog {
-webkit-transform: translate(0, 0);
-ms-transform: translate(0, 0);
-o-transform: translate(0, 0);
transform: translate(0, 0); }

/* line 38, airtel/assets/scss/standard/_modals.scss */
.modal-open .modal {
overflow-x: hidden;
overflow-y: auto; }

/* line 44, airtel/assets/scss/standard/_modals.scss */
.modal-dialog {
position: relative;
width: auto;
margin: 10px; }

/* line 51, airtel/assets/scss/standard/_modals.scss */
.modal-content {
position: relative;
background-color: #fff;
border: 1px solid #999;
border: 1px solid rgba(0, 0, 0, 0.2);
border-radius: 6px;
-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
background-clip: padding-box;
outline: 0; }

/* line 64, airtel/assets/scss/standard/_modals.scss */
.modal-backdrop {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 1040;
background-color: #000; }
/* line 73, airtel/assets/scss/standard/_modals.scss */
.modal-backdrop.fade {
opacity: 0;
filter: alpha(opacity=0); }
/* line 74, airtel/assets/scss/standard/_modals.scss */
.modal-backdrop.in {
opacity: 0.5;
filter: alpha(opacity=50); }

/* line 79, airtel/assets/scss/standard/_modals.scss */
.modal-header {
padding: 15px;
border-bottom: 1px solid #e5e5e5; }
/* line 14, airtel/assets/scss/standard/mixins/_clearfix.scss */
.modal-header:before, .modal-header:after {
content: " ";
display: table; }
/* line 19, airtel/assets/scss/standard/mixins/_clearfix.scss */
.modal-header:after {
clear: both; }

/* line 85, airtel/assets/scss/standard/_modals.scss */
.modal-header .close {
margin-top: -2px; }

/* line 90, airtel/assets/scss/standard/_modals.scss */
.modal-title {
margin: 0;
line-height: 1.5; }

/* line 97, airtel/assets/scss/standard/_modals.scss */
.modal-body {
position: relative;
padding: 15px; 
max-height: 75vh;
overflow-y: scroll;}

/* line 103, airtel/assets/scss/standard/_modals.scss */
.modal-footer {
padding: 15px;
text-align: right;
border-top: 1px solid #e5e5e5;
position: absolute;
bottom: -30px;
width: 100%;
}
/* line 14, airtel/assets/scss/standard/mixins/_clearfix.scss */
.modal-footer:before, .modal-footer:after {
content: " ";
display: table; }
/* line 19, airtel/assets/scss/standard/mixins/_clearfix.scss */
.modal-footer:after {
clear: both; }
/* line 110, airtel/assets/scss/standard/_modals.scss */
.modal-footer .btn + .btn {
margin-left: 5px;
margin-bottom: 0; }
/* line 115, airtel/assets/scss/standard/_modals.scss */
.modal-footer .btn-group .btn + .btn {
margin-left: -1px; }
/* line 119, airtel/assets/scss/standard/_modals.scss */
.modal-footer .btn-block + .btn-block {
margin-left: 0; }

/* line 125, airtel/assets/scss/standard/_modals.scss */
.modal-scrollbar-measure {
position: absolute;
top: -9999px;
width: 50px;
height: 50px;
overflow: scroll; }

@media (min-width: 768px) {
/* line 136, airtel/assets/scss/standard/_modals.scss */
.modal-dialog {
width: 600px;
margin: 30px auto; }

/* line 140, airtel/assets/scss/standard/_modals.scss */
.modal-content {
-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }

/* line 145, airtel/assets/scss/standard/_modals.scss */
.modal-sm {
width: 300px; }   }
@media (min-width: 992px) {
/* line 149, airtel/assets/scss/standard/_modals.scss */
.modal-lg {
width: 900px; }  }
/* line 153, airtel/assets/scss/standard/_modals.scss */
.modal-dialog {
position: absolute;
left: 0;
top: 50%;
margin-top: 0px;
margin-bottom: 0px;
transform: translateY(-50%) !important;
-webkit-transform: translateY(-50%) !important;
-ms-transform: translateY(-50%) !important;
right: 0; }

/* line 154, airtel/assets/scss/standard/_modals.scss */
body.modal-open {
padding-right: 17px; }

/* line 155, airtel/assets/scss/standard/_modals.scss */
.modal-content {
border-radius: 0px;
border: none;
box-shadow: none;
margin: 30px auto; }

/* line 156, airtel/assets/scss/standard/_modals.scss */
.modal-header {
border: none;
padding: 40px 40px 15px; }

/* line 157, airtel/assets/scss/standard/_modals.scss */
.modal-footer {
border: none;
padding: 10px 40px 40px; }

/* line 158, airtel/assets/scss/standard/_modals.scss */
.modal-header h3 {
font-size: 39px; }

/* line 159, airtel/assets/scss/standard/_modals.scss */
.modal-header .close {
position: absolute;
right: 22px;
top: 20px;
color: #333;
font-size: 20px;
opacity: 0.8; }

/* line 160, airtel/assets/scss/standard/_modals.scss */
.modal-body {
padding: 15px 40px; }

/* line 161, airtel/assets/scss/standard/_modals.scss */
.modal-body [class^="col-"] {
margin-bottom: 40px; }

/* line 162, airtel/assets/scss/standard/_modals.scss */
.modal-body [class^="col-"]:last-child {
margin-bottom: 10px; }

/* line 163, airtel/assets/scss/standard/_modals.scss */
.modal-body dt {
float: left;
width: 13%;
margin-bottom: 30px; }

/* line 164, airtel/assets/scss/standard/_modals.scss */
.modal-body dd {
float: left;
width: 87%;
margin-bottom: 30px; }

/* line 165, airtel/assets/scss/standard/_modals.scss */
.modal-body .odd-even {
margin-right: -50px;
margin-left: -50px;
padding: 10px 40px; }

/* line 166, airtel/assets/scss/standard/_modals.scss */
.modal-body .odd-even:nth-child(odd) {
background: #F4F4F4; }

/* line 167, airtel/assets/scss/standard/_modals.scss */
.modal-backdrop {
background-color: #d4d4d4; }

/* line 168, airtel/assets/scss/standard/_modals.scss */
.modal-backdrop.in {
opacity: 0.95; }

/* line 170, airtel/assets/scss/standard/_modals.scss */
.modal-footer .action {
margin: 0; }

@media (max-width: 991px) {
/* line 172, airtel/assets/scss/standard/_modals.scss */
.modal-dialog.modal-lg {
margin: 0;
border: none;
position: absolute;
height: 100%;
width: 100%; }
/* line 174, airtel/assets/scss/standard/_modals.scss */
.modal-dialog.modal-lg .modal-content {
  position: absolute;
  height: 100%;
  width: 100%;
  border: none;
  overflow: auto;
  margin: 0; }
/* line 175, airtel/assets/scss/standard/_modals.scss */
.modal-dialog.modal-lg .modal-body {
  padding: 15px; }
/* line 176, airtel/assets/scss/standard/_modals.scss */
.modal-dialog.modal-lg .modal-header {
  padding-left: 15px;
  padding-right: 15px; }  }


  /* line 5, airtel/assets/scss/standard/mixins/_text-emphasis.scss */
.text-primary {
color: #E40000; }

/* line 8, airtel/assets/scss/standard/mixins/_text-emphasis.scss */
a.text-primary:hover,
a.text-primary:focus {
color: #b10000; }

/* line 5, airtel/assets/scss/standard/mixins/_text-emphasis.scss */
.text-success {
color: #3c763d; }

/* line 8, airtel/assets/scss/standard/mixins/_text-emphasis.scss */
a.text-success:hover,
a.text-success:focus {
color: #2b542c; }

/* line 5, airtel/assets/scss/standard/mixins/_text-emphasis.scss */
.text-info {
color: #31708f; }

/* line 8, airtel/assets/scss/standard/mixins/_text-emphasis.scss */
a.text-info:hover,
a.text-info:focus {
color: #245269; }

/* line 5, airtel/assets/scss/standard/mixins/_text-emphasis.scss */
.text-warning {
color: #8a6d3b; }

/* line 8, airtel/assets/scss/standard/mixins/_text-emphasis.scss */
a.text-warning:hover,
a.text-warning:focus {
color: #66512c; }

/* line 5, airtel/assets/scss/standard/mixins/_text-emphasis.scss */
.text-danger {
color: #a94442; }

/* line 8, airtel/assets/scss/standard/mixins/_text-emphasis.scss */
a.text-danger:hover,
a.text-danger:focus {
color: #843534; }

.underline {
text-decoration: underline;
}

.cursor {
cursor: pointer;
}
/* line 90, airtel/assets/scss/standard/_utilities.scss */
.cursor-pointer {
cursor: pointer; }


.show {
display: block !important; }


.btnsubmit{
padding:13px 40px 12px 40px!important;
margin-bottom:40px!important;
border-radius:4px;

}

.btnpreview{
border-radius:4px!important;
}

/* .btn {
width: 100%; } */

.btn-text.btn-lg, .btn-group-lg > .btn-text.btn {
margin: 16px 0px !important; }


/* line 77, airtel/assets/scss/standard/_print.scss */
.btn > .caret,
.dropup > .btn > .caret {
border-top-color: #000 !important; }


.btn {
display: inline-block;
margin-bottom: 0;
font-weight: 100;
text-align: center;
text-transform: uppercase;
transition: 0.5s;
letter-spacing: 2px;
vertical-align: middle;
border: none;
touch-action: manipulation;
cursor: pointer;
background-image: none;
white-space: nowrap;
padding: 17px 40px;
font-size: 20px;
line-height: 1.3333333;
color: #fff;
background-color: #E40000;
border-color: #df0000;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none; }
/* line 11, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn:focus, .btn.focus {
color: #fff;
background-color: #b10000;
border-color: #5f0000; }
/* line 17, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn:hover {
color: #fff;
background-color: #b10000;
border-color: #a20000; }
/* line 22, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn:active, .btn.active, .open > .btn.dropdown-toggle {
color: #fff;
background-color: #b10000;
border-color: #a20000; }
/* line 29, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn:active:hover, .btn:active:focus, .btn:active.focus, .btn.active:hover, .btn.active:focus, .btn.active.focus, .open > .btn.dropdown-toggle:hover, .open > .btn.dropdown-toggle:focus, .open > .btn.dropdown-toggle.focus {
color: #fff;
background-color: #8d0000;
border-color: #5f0000; }
/* line 37, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn:active, .btn.active, .open > .btn.dropdown-toggle {
background-image: none; }
/* line 45, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn.disabled:hover, .btn.disabled:focus, .btn.disabled.focus, .btn[disabled]:hover, .btn[disabled]:focus, .btn[disabled].focus, fieldset[disabled] .btn:hover, fieldset[disabled] .btn:focus, fieldset[disabled] .btn.focus {
background-color: #E40000;
border-color: #df0000; }
/* line 53, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn .badge {
color: #E40000;
background-color: #fff; }
/* line 32, airtel/assets/scss/standard/_buttons.scss */
.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
outline: 0 none; }
/* line 39, airtel/assets/scss/standard/_buttons.scss */
.btn:hover, .btn:focus, .btn.focus {
color: #333;
text-decoration: none;
outline: none; }
/* line 47, airtel/assets/scss/standard/_buttons.scss */
.btn:active, .btn.active {
outline: 0;
background-image: none;
-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
/* line 54, airtel/assets/scss/standard/_buttons.scss */
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
cursor: not-allowed;
opacity: 0.65;
filter: alpha(opacity=65);
-webkit-box-shadow: none;
box-shadow: none; }

/* line 66, airtel/assets/scss/standard/_buttons.scss */
a.btn.disabled, fieldset[disabled] a.btn {
pointer-events: none; }

/* line 76, airtel/assets/scss/standard/_buttons.scss */
.btn-default {
color: #333;
background-color: #fff;
border-color: #ccc; }
/* line 11, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-default:focus, .btn-default.focus {
color: #333;
background-color: #e6e6e6;
border-color: #8c8c8c; }
/* line 17, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-default:hover {
color: #333;
background-color: #e6e6e6;
border-color: #adadad; }
/* line 22, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
color: #333;
background-color: #e6e6e6;
border-color: #adadad; }
/* line 29, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open > .btn-default.dropdown-toggle:hover, .open > .btn-default.dropdown-toggle:focus, .open > .btn-default.dropdown-toggle.focus {
color: #333;
background-color: #d4d4d4;
border-color: #8c8c8c; }
/* line 37, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
background-image: none; }
/* line 45, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default.focus {
background-color: #fff;
border-color: #ccc; }
/* line 53, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-default .badge {
color: #fff;
background-color: #333; }

/* line 79, airtel/assets/scss/standard/_buttons.scss */
.btn-primary {
color: #fff;
background-color: #E40000;
border-color: #df0000; }
/* line 11, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-primary:focus, .btn-primary.focus {
color: #fff;
background-color: #b10000;
border-color: #5f0000; }
/* line 17, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-primary:hover {
color: #fff;
background-color: #b10000;
border-color: #a20000; }
/* line 22, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
color: #fff;
background-color: #b10000;
border-color: #a20000; }
/* line 29, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle.focus {
color: #fff;
background-color: #8d0000;
border-color: #5f0000; }
/* line 37, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
background-image: none; }
/* line 45, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary.focus {
background-color: #E40000;
border-color: #df0000; }
/* line 53, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-primary .badge {
color: #E40000;
background-color: #fff; }

/* line 83, airtel/assets/scss/standard/_buttons.scss */
.btn-success {
color: #fff;
background-color: #5cb85c;
border-color: #4cae4c; }
/* line 11, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-success:focus, .btn-success.focus {
color: #fff;
background-color: #449d44;
border-color: #255625; }
/* line 17, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-success:hover {
color: #fff;
background-color: #449d44;
border-color: #398439; }
/* line 22, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
color: #fff;
background-color: #449d44;
border-color: #398439; }
/* line 29, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus, .open > .btn-success.dropdown-toggle:hover, .open > .btn-success.dropdown-toggle:focus, .open > .btn-success.dropdown-toggle.focus {
color: #fff;
background-color: #398439;
border-color: #255625; }
/* line 37, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
background-image: none; }
/* line 45, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success.focus {
background-color: #5cb85c;
border-color: #4cae4c; }
/* line 53, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-success .badge {
color: #5cb85c;
background-color: #fff; }

/* line 87, airtel/assets/scss/standard/_buttons.scss */
.btn-info {
color: #fff;
background-color: #5bc0de;
border-color: #46b8da; }
/* line 11, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-info:focus, .btn-info.focus {
color: #fff;
background-color: #31b0d5;
border-color: #1b6d85; }
/* line 17, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-info:hover {
color: #fff;
background-color: #31b0d5;
border-color: #269abc; }
/* line 22, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
color: #fff;
background-color: #31b0d5;
border-color: #269abc; }
/* line 29, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus, .open > .btn-info.dropdown-toggle:hover, .open > .btn-info.dropdown-toggle:focus, .open > .btn-info.dropdown-toggle.focus {
color: #fff;
background-color: #269abc;
border-color: #1b6d85; }
/* line 37, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
background-image: none; }
/* line 45, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info.focus {
background-color: #5bc0de;
border-color: #46b8da; }
/* line 53, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-info .badge {
color: #5bc0de;
background-color: #fff; }

/* line 91, airtel/assets/scss/standard/_buttons.scss */
.btn-warning {
color: #fff;
background-color: #f0ad4e;
border-color: #eea236; }
/* line 11, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-warning:focus, .btn-warning.focus {
color: #fff;
background-color: #ec971f;
border-color: #985f0d; }
/* line 17, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-warning:hover {
color: #fff;
background-color: #ec971f;
border-color: #d58512; }
/* line 22, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
color: #fff;
background-color: #ec971f;
border-color: #d58512; }
/* line 29, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus, .open > .btn-warning.dropdown-toggle:hover, .open > .btn-warning.dropdown-toggle:focus, .open > .btn-warning.dropdown-toggle.focus {
color: #fff;
background-color: #d58512;
border-color: #985f0d; }
/* line 37, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
background-image: none; }
/* line 45, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning.focus {
background-color: #f0ad4e;
border-color: #eea236; }
/* line 53, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-warning .badge {
color: #f0ad4e;
background-color: #fff; }

/* line 95, airtel/assets/scss/standard/_buttons.scss */
.btn-danger {
color: #fff;
background-color: #d9534f;
border-color: #d43f3a; }
/* line 11, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-danger:focus, .btn-danger.focus {
color: #fff;
background-color: #c9302c;
border-color: #761c19; }
/* line 17, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-danger:hover {
color: #fff;
background-color: #c9302c;
border-color: #ac2925; }
/* line 22, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
color: #fff;
background-color: #c9302c;
border-color: #ac2925; }
/* line 29, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus, .open > .btn-danger.dropdown-toggle:hover, .open > .btn-danger.dropdown-toggle:focus, .open > .btn-danger.dropdown-toggle.focus {
color: #fff;
background-color: #ac2925;
border-color: #761c19; }
/* line 37, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
background-image: none; }
/* line 45, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger.focus {
background-color: #d9534f;
border-color: #d43f3a; }
/* line 53, airtel/assets/scss/standard/mixins/_buttons.scss */
.btn-danger .badge {
color: #d9534f;
background-color: #fff; }

/* line 99, airtel/assets/scss/standard/_buttons.scss */
.btn-black {
background: #000 !important; }

/* line 106, airtel/assets/scss/standard/_buttons.scss */
.btn-link {
color: #E40000;
font-weight: normal;
border-radius: 0; }
/* line 111, airtel/assets/scss/standard/_buttons.scss */
.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
background-color: transparent;
-webkit-box-shadow: none;
box-shadow: none; }
/* line 119, airtel/assets/scss/standard/_buttons.scss */
.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
border-color: transparent; }
/* line 125, airtel/assets/scss/standard/_buttons.scss */
.btn-link:hover, .btn-link:focus {
color: #A30000;
text-decoration: none;
background-color: transparent; }
/* line 133, airtel/assets/scss/standard/_buttons.scss */
.btn-link[disabled]:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus {
color: #EBEBEB;
text-decoration: none; }

/* line 144, airtel/assets/scss/standard/_buttons.scss */
.btn-text, .btn-text:hover, .btn-text:focus {
background: transparent;
color: #e40000; }

/* line 145, airtel/assets/scss/standard/_buttons.scss */
.btn-text.btn-lg, .btn-group-lg > .btn-text.btn {
margin: 16px 30px !important; }

/* line 147, airtel/assets/scss/standard/_buttons.scss */
.btn-lg, .btn-group-lg > .btn, .btn-large {
padding: 17px 40px;
font-size: 20px;
line-height: 1.3333333; }

.btn-md, .btn-group-md > .btn, .btn-medium {
  padding: 15px 30px;
  font-size: 18px;
  line-height: 1.3333333; }

/* line 151, airtel/assets/scss/standard/_buttons.scss */
.btn-sm, .btn-group-sm > .btn, .btn.small {
padding: 12px 25px;
font-size: 14px;
line-height: 16px; }

/* line 155, airtel/assets/scss/standard/_buttons.scss */
.btn-xs, .btn-group-xs > .btn {
padding: 2px 8px;
font-size: 12px;
line-height: 1.58; }

/* line 163, airtel/assets/scss/standard/_buttons.scss */
.btn-block {
display: block;
width: 100%; }

/* line 169, airtel/assets/scss/standard/_buttons.scss */
.btn-block + .btn-block {
margin-top: 5px; }

/* line 177, airtel/assets/scss/standard/_buttons.scss */
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
width: 100%; }

/* line 184, airtel/assets/scss/standard/_buttons.scss */
.btn.ok {
background: #7c4d9e;
border-color: #7c4d9e; }

/* line 185, airtel/assets/scss/standard/_buttons.scss */
.btn.ok:hover {
background: #4a2e5e;
border-color: #4a2e5e; }

/* line 186, airtel/assets/scss/standard/_buttons.scss */
.button:hover, .btn:not(.btn-success):not(.btn-info):hover, .btn:not(.btn-success):not(.btn-info):focus, .button:focus {
background: #a30000;
border-color: #a30000;
color: #fff; }

/* line 187, airtel/assets/scss/standard/_buttons.scss */
.button, .form-group label {
font-family: Tondo; }

/* line 188, airtel/assets/scss/standard/_buttons.scss */
.red-hover:hover {
color: #E40000 !important; }

/* line 189, airtel/assets/scss/standard/_buttons.scss */
.btn.xsmall {
padding: 3px 7px !important;
font-size: 11px !important;
min-width: 0; }

/* line 190, airtel/assets/scss/standard/_buttons.scss */
.btn.xsmall:hover {
color: #000 !important; }

/* line 193, airtel/assets/scss/standard/_buttons.scss */
.input-group .btn {
float: none; }

/* line 194, airtel/assets/scss/standard/_buttons.scss */
.btn [class^="icon-"] {
vertical-align: middle;
font-size: 85%;
margin-top: -4px; }

/* line 195, airtel/assets/scss/standard/_buttons.scss */
.input-group + .btn {
float: none;
margin-right: 0px;
margin-top: 8px; }

/* line 196, airtel/assets/scss/standard/_buttons.scss */
.btn-stroked, .btn-stroked:focus {
background: 0 0 !important;
box-shadow: inset 0 0 0 2px #e40000 !important;
color: #e40000; }

/* line 197, airtel/assets/scss/standard/_buttons.scss */
.btn-stroked:hover {
box-shadow: inset 0 0 0 2px #a30000;
color: #a30000; }


/* line 199, airtel/assets/scss/standard/_buttons.scss */
.btn-purple-stroked {
box-shadow: inset 0 0 0 3px #7c4d9e !important;
color: #7c4d9e;
background: 0 0 !important; }

/* line 200, airtel/assets/scss/standard/_buttons.scss */
.btn-purple-stroked:hover, .btn-purple-stroked:focus {
box-shadow: inset 0 0 0 4px #662d8f !important;
color: #662d8f; }

/* line 201, airtel/assets/scss/standard/_buttons.scss */
.btn-purple {
background: #7c4d9e;
border-color: #7c4d9e;
color: #fff; }

/* line 202, airtel/assets/scss/standard/_buttons.scss */
.btn-purple:hover, .btn-purple:focus {
background: #662d8f;
color: #fff; }

/* line 203, airtel/assets/scss/standard/_buttons.scss */
.btn-text, .btn-text:hover, .btn-text:focus, .btn-text:active:focus, .btn-text:active:hover {
background: transparent;
color: #e40000;
outline: none; }

/* line 204, airtel/assets/scss/standard/_buttons.scss */
.btn-text {
padding: 0px;
margin: 12px 30px;
box-shadow: none !important; }

/* line 205, airtel/assets/scss/standard/_buttons.scss */
.btn-text.btn-lg, .btn-group-lg > .btn-text.btn {
margin: 16px 30px; }
.btn-group,
.btn-group-vertical {
position: relative;
display: inline-block;
vertical-align: middle; }
/* line 11, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn,
.btn-group-vertical > .btn {
position: relative;
float: left; }
/* line 15, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2; }

/* line 26, airtel/assets/scss/standard/_button-groups.scss */
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
margin-left: -1px; }

/* line 35, airtel/assets/scss/standard/_button-groups.scss */
.btn-toolbar {
margin-left: -5px; }
/* line 14, airtel/assets/scss/standard/mixins/_clearfix.scss */
.btn-toolbar:before, .btn-toolbar:after {
content: " ";
display: table; }
/* line 19, airtel/assets/scss/standard/mixins/_clearfix.scss */
.btn-toolbar:after {
clear: both; }
/* line 39, airtel/assets/scss/standard/_button-groups.scss */
.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
float: left; }
/* line 44, airtel/assets/scss/standard/_button-groups.scss */
.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
margin-left: 5px; }

/* line 51, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
border-radius: 0; }

/* line 56, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn:first-child {
margin-left: 0; }
/* line 58, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
border-bottom-right-radius: 0;
border-top-right-radius: 0; }

/* line 63, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
border-bottom-left-radius: 0;
border-top-left-radius: 0; }

/* line 69, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn-group {
float: left; }

/* line 72, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
border-radius: 0; }

/* line 76, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
border-bottom-right-radius: 0;
border-top-right-radius: 0; }

/* line 81, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
border-bottom-left-radius: 0;
border-top-left-radius: 0; }

/* line 86, airtel/assets/scss/standard/_button-groups.scss */
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
outline: 0; }

/* line 105, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn + .dropdown-toggle {
padding-left: 8px;
padding-right: 8px; }

/* line 109, airtel/assets/scss/standard/_button-groups.scss */
.btn-group > .btn-lg + .dropdown-toggle, .btn-group-lg.btn-group > .btn + .dropdown-toggle {
padding-left: 12px;
padding-right: 12px; }

/* line 116, airtel/assets/scss/standard/_button-groups.scss */
.btn-group.open .dropdown-toggle {
-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
/* line 120, airtel/assets/scss/standard/_button-groups.scss */
.btn-group.open .dropdown-toggle.btn-link {
-webkit-box-shadow: none;
box-shadow: none; }

/* line 127, airtel/assets/scss/standard/_button-groups.scss */
.btn .caret {
margin-left: 0; }

/* line 131, airtel/assets/scss/standard/_button-groups.scss */
.btn-lg .caret, .btn-group-lg > .btn .caret {
border-width: 5px 5px 0;
border-bottom-width: 0; }

/* line 136, airtel/assets/scss/standard/_button-groups.scss */
.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret {
border-width: 0 5px 5px; }

/* line 145, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
display: block;
float: none;
width: 100%;
max-width: 100%; }
/* line 14, airtel/assets/scss/standard/mixins/_clearfix.scss */
.btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after {
content: " ";
display: table; }
/* line 19, airtel/assets/scss/standard/mixins/_clearfix.scss */
.btn-group-vertical > .btn-group:after {
clear: both; }
/* line 157, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-vertical > .btn-group > .btn {
float: none; }
/* line 162, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
margin-top: -1px;
margin-left: 0; }

/* line 172, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
border-radius: 0; }
/* line 175, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-vertical > .btn:first-child:not(:last-child) {
border-top-right-radius: 0px;
border-top-left-radius: 0px;
border-bottom-right-radius: 0;
border-bottom-left-radius: 0; }
/* line 179, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-vertical > .btn:last-child:not(:first-child) {
border-top-right-radius: 0;
border-top-left-radius: 0;
border-bottom-right-radius: 0px;
border-bottom-left-radius: 0px; }

/* line 184, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
border-radius: 0; }

/* line 188, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
border-bottom-right-radius: 0;
border-bottom-left-radius: 0; }

/* line 193, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
border-top-right-radius: 0;
border-top-left-radius: 0; }

/* line 201, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-justified {
display: table;
width: 100%;
table-layout: fixed;
border-collapse: separate; }
/* line 206, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
float: none;
display: table-cell;
width: 1%; }
/* line 212, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-justified > .btn-group .btn {
width: 100%; }
/* line 216, airtel/assets/scss/standard/_button-groups.scss */
.btn-group-justified > .btn-group .dropdown-menu {
left: auto; }

/* line 237, airtel/assets/scss/standard/_button-groups.scss */
[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
position: absolute;
clip: rect(0, 0, 0, 0);
pointer-events: none; }

/* line 207, airtel/assets/scss/custom/_panding.scss */
.modal-footer .btn + .btn {
margin-left: 0 !important; }

/* line 166, airtel/assets/scss/custom/_panding.scss */
.modal-footer .btn {
float: right; }

.color-dark-gray {
color: #555555 !important; }

.font-size-14, .fs-14 {
font-size: 14px !important;
line-height: 17px;
letter-spacing: 1.2px; }

.bg-purple {
  background: #EFF0F4; }

/* line 51, airtel/assets/scss/custom/_common.scss */
.bg-dark-purple {
  background: #4A2E5E;
  color: #fff; }

/* line 52, airtel/assets/scss/custom/_common.scss */
.bg-green {
  background: #96BA3A;
  color: #fff; }

/* line 53, airtel/assets/scss/custom/_common.scss */
.bg-dark-green {
  background: #008300; }

/* line 54, airtel/assets/scss/custom/_common.scss */
.bg-white {
  background: #fff; }

/* line 55, airtel/assets/scss/custom/_common.scss */
.bg-lime {
  background: #D3D000; }

/* line 56, airtel/assets/scss/custom/_common.scss */
.bg-red {
  background-color: #E40000 !important;
  color: #fff; }

/* line 57, airtel/assets/scss/custom/_common.scss */
.bg-red-dark {
  background-color: #B50A02;
  color: #fff; }

/* line 58, airtel/assets/scss/custom/_common.scss */
.bg-yellow-light {
  background: #FAF6E9; }

/* line 59, airtel/assets/scss/custom/_common.scss */
.bg-blue {
  background: rgba(74, 46, 94, 0.8);
  color: #fff; }

/* line 60, airtel/assets/scss/custom/_common.scss */
.bg-maroon {
  background: #A30000;
  color: #EBEBEB; }

/* line 61, airtel/assets/scss/custom/_common.scss */
.bg-maroon-dark {
  background-color: #6B050B;
  color: #fff; }

/* line 62, airtel/assets/scss/custom/_common.scss */
.bg-maroon-light {
  background: #8D0707;
  color: #EBEBEB; }

/* line 63, airtel/assets/scss/custom/_common.scss */
.bg-gray {
  background: #EBEBEB; }

/* line 64, airtel/assets/scss/custom/_common.scss */
.bg-gray-light {
  background: #FBFBFB; }

/* line 65, airtel/assets/scss/custom/_common.scss */
.bg-gray-mid-light, .bg-body {
  background: #f6f6f6; }

/* line 66, airtel/assets/scss/custom/_common.scss */
.bg-gray-darker {
  background: #555555; }

/* line 67, airtel/assets/scss/custom/_common.scss */
.bg-gray-dark {
  background: #D4D4D4; }

/* line 68, airtel/assets/scss/custom/_common.scss */
.bg-blck {
  background: #000; }

/* line 69, airtel/assets/scss/custom/_common.scss */
.bg-black {
  background: #000; }

/* line 73, airtel/assets/scss/custom/_common.scss */
.margin-0, .m-0 {
  margin: 0 !important; }

/* line 74, airtel/assets/scss/custom/_common.scss */
.padding-0, .p-0 {
  padding: 0 !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-0, .pt-0 {
  padding-top: 0px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-0, .mt-0 {
  margin-top: 0px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-0, .pr-0 {
  padding-right: 0px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-0, .mr-0 {
  margin-right: 0px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-0, .pb-0 {
  padding-bottom: 0px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-0, .mb-0 {
  margin-bottom: 0px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-0, .pl-0 {
  padding-left: 0px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-0, .ml-0 {
  margin-left: 0px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-5, .pt-5 {
  padding-top: 5px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-5, .mt-5 {
  margin-top: 5px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-5, .pr-5 {
  padding-right: 5px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-5, .mr-5 {
  margin-right: 5px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-5, .pb-5 {
  padding-bottom: 5px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-5, .mb-5 {
  margin-bottom: 5px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-5, .pl-5 {
  padding-left: 5px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-5, .ml-5 {
  margin-left: 5px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-10, .pt-10 {
  padding-top: 10px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-10, .mt-10 {
  margin-top: 10px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-10, .pr-10 {
  padding-right: 10px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-10, .mr-10 {
  margin-right: 10px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-10, .pb-10 {
  padding-bottom: 10px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-10, .mb-10 {
  margin-bottom: 10px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-10, .pl-10 {
  padding-left: 10px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-10, .ml-10 {
  margin-left: 10px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-15, .pt-15 {
  padding-top: 15px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-15, .mt-15 {
  margin-top: 15px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-15, .pr-15 {
  padding-right: 15px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-15, .mr-15 {
  margin-right: 15px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-15, .pb-15 {
  padding-bottom: 15px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-15, .mb-15 {
  margin-bottom: 15px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-15, .pl-15 {
  padding-left: 15px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-15, .ml-15 {
  margin-left: 15px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-20, .pt-20 {
  padding-top: 20px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-20, .mt-20 {
  margin-top: 20px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-20, .pr-20 {
  padding-right: 20px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-20, .mr-20 {
  margin-right: 20px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-20, .pb-20 {
  padding-bottom: 20px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-20, .mb-20 {
  margin-bottom: 20px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-20, .pl-20 {
  padding-left: 20px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-20, .ml-20 {
  margin-left: 20px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-25, .pt-25 {
  padding-top: 25px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-25, .mt-25 {
  margin-top: 25px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-25, .pr-25 {
  padding-right: 25px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-25, .mr-25 {
  margin-right: 25px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-25, .pb-25 {
  padding-bottom: 25px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-25, .mb-25 {
  margin-bottom: 25px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-25, .pl-25 {
  padding-left: 25px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-25, .ml-25 {
  margin-left: 25px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-30, .pt-30 {
  padding-top: 30px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-30, .mt-30 {
  margin-top: 30px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-30, .pr-30 {
  padding-right: 30px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-30, .mr-30 {
  margin-right: 30px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-30, .pb-30 {
  padding-bottom: 30px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-30, .mb-30 {
  margin-bottom: 30px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-30, .pl-30 {
  padding-left: 30px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-30, .ml-30 {
  margin-left: 30px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-35, .pt-35 {
  padding-top: 35px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-35, .mt-35 {
  margin-top: 35px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-35, .pr-35 {
  padding-right: 35px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-35, .mr-35 {
  margin-right: 35px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-35, .pb-35 {
  padding-bottom: 35px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-35, .mb-35 {
  margin-bottom: 35px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-35, .pl-35 {
  padding-left: 35px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-35, .ml-35 {
  margin-left: 35px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-40, .pt-40 {
  padding-top: 40px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-40, .mt-40 {
  margin-top: 40px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-40, .pr-40 {
  padding-right: 40px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-40, .mr-40 {
  margin-right: 40px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-40, .pb-40 {
  padding-bottom: 40px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-40, .mb-40 {
  margin-bottom: 40px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-40, .pl-40 {
  padding-left: 40px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-40, .ml-40 {
  margin-left: 40px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-50, .pt-50 {
  padding-top: 50px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-50, .mt-50 {
  margin-top: 50px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-50, .pr-50 {
  padding-right: 50px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-50, .mr-50 {
  margin-right: 50px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-50, .pb-50 {
  padding-bottom: 50px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-50, .mb-50 {
  margin-bottom: 50px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-50, .pl-50 {
  padding-left: 50px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-50, .ml-50 {
  margin-left: 50px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-60, .pt-60 {
  padding-top: 60px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-60, .mt-60 {
  margin-top: 60px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-60, .pr-60 {
  padding-right: 60px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-60, .mr-60 {
  margin-right: 60px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-60, .pb-60 {
  padding-bottom: 60px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-60, .mb-60 {
  margin-bottom: 60px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-60, .pl-60 {
  padding-left: 60px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-60, .ml-60 {
  margin-left: 60px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-70, .pt-70 {
  padding-top: 70px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-70, .mt-70 {
  margin-top: 70px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-70, .pr-70 {
  padding-right: 70px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-70, .mr-70 {
  margin-right: 70px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-70, .pb-70 {
  padding-bottom: 70px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-70, .mb-70 {
  margin-bottom: 70px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-70, .pl-70 {
  padding-left: 70px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-70, .ml-70 {
  margin-left: 70px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-80, .pt-80 {
  padding-top: 80px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-80, .mt-80 {
  margin-top: 80px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-80, .pr-80 {
  padding-right: 80px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-80, .mr-80 {
  margin-right: 80px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-80, .pb-80 {
  padding-bottom: 80px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-80, .mb-80 {
  margin-bottom: 80px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-80, .pl-80 {
  padding-left: 80px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-80, .ml-80 {
  margin-left: 80px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-100, .pt-100 {
  padding-top: 100px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-100, .mt-100 {
  margin-top: 100px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-100, .pr-100 {
  padding-right: 100px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-100, .mr-100 {
  margin-right: 100px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-100, .pb-100 {
  padding-bottom: 100px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-100, .mb-100 {
  margin-bottom: 100px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-100, .pl-100 {
  padding-left: 100px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-100, .ml-100 {
  margin-left: 100px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-110, .pt-110 {
  padding-top: 110px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-110, .mt-110 {
  margin-top: 110px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-110, .pr-110 {
  padding-right: 110px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-110, .mr-110 {
  margin-right: 110px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-110, .pb-110 {
  padding-bottom: 110px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-110, .mb-110 {
  margin-bottom: 110px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-110, .pl-110 {
  padding-left: 110px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-110, .ml-110 {
  margin-left: 110px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-top-140, .pt-140 {
  padding-top: 140px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-top-140, .mt-140 {
  margin-top: 140px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-right-140, .pr-140 {
  padding-right: 140px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-right-140, .mr-140 {
  margin-right: 140px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-bottom-140, .pb-140 {
  padding-bottom: 140px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-bottom-140, .mb-140 {
  margin-bottom: 140px !important; }

/* line 80, airtel/assets/scss/custom/_common.scss */
.padding-left-140, .pl-140 {
  padding-left: 140px !important; }

/* line 83, airtel/assets/scss/custom/_common.scss */
.margin-left-140, .ml-140 {
  margin-left: 140px !important; }

/* line 92, airtel/assets/scss/custom/_common.scss */
.display-inline-block, .inline-block {
  display: inline-block; }

/* line 93, airtel/assets/scss/custom/_common.scss */
.display-block {
  display: block; }

/* line 94, airtel/assets/scss/custom/_common.scss */
.display-inline {
  display: inline; }

/* line 95, airtel/assets/scss/custom/_common.scss */
.overflow-hidden {
  overflow: hidden; }

/* line 96, airtel/assets/scss/custom/_common.scss */
.border-top {
  border-top: 1px solid #D4D4D4; }

/* line 97, airtel/assets/scss/custom/_common.scss */
.border-bottom {
  border-bottom: 1px solid #D4D4D4 !important; }

/* line 98, airtel/assets/scss/custom/_common.scss */
.border-top-gray {
  border-top: 1px solid #707070; }

/* line 99, airtel/assets/scss/custom/_common.scss */
.border-bottom-gray {
  border-bottom: 1px solid #707070; }

/* line 100, airtel/assets/scss/custom/_common.scss */
.border-top-dark {
  border-top: 1px solid #9F9F9F; }

/* line 101, airtel/assets/scss/custom/_common.scss */
.border-bottom-dark {
  border-bottom: 1px solid #9F9F9F; }

/* line 102, airtel/assets/scss/custom/_common.scss */
.b-none {
  border: none; }

/* line 105, airtel/assets/scss/custom/_common.scss */
.link {
  color: #E40000;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap; }

/* line 106, airtel/assets/scss/custom/_common.scss */
.link-lg {
  letter-spacing: 2px;
  font-size: 20px; }

/* line 107, airtel/assets/scss/custom/_common.scss */
.link-sm {
  letter-spacing: 1.2px;
  font-size: 14px; }

/* line 108, airtel/assets/scss/custom/_common.scss */
.link:hover {
  color: #e40000; }

/* line 109, airtel/assets/scss/custom/_common.scss */
.bg-maroon .link,
.bg-dark-purple .link,
.bg-blue .link,
.bg-red .link,
.bg-green .link {
  color: #fff;
  font-size: 20px; }
  @media screen and (max-width: 768px) {
    /* line 109, airtel/assets/scss/custom/_common.scss */
    .bg-maroon .link,
    .bg-dark-purple .link,
    .bg-blue .link,
    .bg-red .link,
    .bg-green .link {
      font-size: 14px; } }

/* line 121, airtel/assets/scss/custom/_common.scss */
.link[class*="color-"]:hover {
  color: inherit; }

@media screen and (min-width: 992px) {
  /* line 123, airtel/assets/scss/custom/_common.scss */
  .pull-right-md {
    float: right; }

  /* line 124, airtel/assets/scss/custom/_common.scss */
  .pull-left-md {
    float: left; } }
@media screen and (min-width: 768px) {
  /* line 127, airtel/assets/scss/custom/_common.scss */
  .pull-right-sm {
    float: right; }

  /* line 128, airtel/assets/scss/custom/_common.scss */
  .pull-left-sm {
    float: left; } }


    .row {
      margin-left: -15px;
      margin-right: -15px; }
      /* line 14, airtel/assets/scss/standard/mixins/_clearfix.scss */
      .row:before, .row:after {
        content: " ";
        display: table; }
      /* line 19, airtel/assets/scss/standard/mixins/_clearfix.scss */
      .row:after {
        clear: both; }
    
    .row-clm {
      margin-left: -15px;
      margin-right: -15px; 
    }
    
    /* line 14, airtel/assets/scss/standard/mixins/_clearfix.scss */
    .row-clm:before, .row-clm:after {
        content: " ";
        display: table; 
        width: 100%
    }
    /* line 19, airtel/assets/scss/standard/mixins/_clearfix.scss */
    .row-clm:after {
        clear: both; 
    }
    
    
    /* line 11, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
    
    /* line 27, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
      float: left; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-1 {
      width: 8.3333333333%; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-2 {
      width: 16.6666666667%; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-3 {
      width: 25%; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-4 {
      width: 33.3333333333%; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-5 {
      width: 41.6666666667%; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-6 {
      width: 50%; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-7 {
      width: 58.3333333333%; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-8 {
      width: 66.6666666667%; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-9 {
      width: 75%; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-10 {
      width: 83.3333333333%; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-11 {
      width: 91.6666666667%; }
    
    /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-12 {
      width: 100%; }
    
    /* line 55, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-0 {
      right: auto; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-1 {
      right: 8.3333333333%; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-2 {
      right: 16.6666666667%; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-3 {
      right: 25%; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-4 {
      right: 33.3333333333%; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-5 {
      right: 41.6666666667%; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-6 {
      right: 50%; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-7 {
      right: 58.3333333333%; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-8 {
      right: 66.6666666667%; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-9 {
      right: 75%; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-10 {
      right: 83.3333333333%; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-11 {
      right: 91.6666666667%; }
    
    /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-pull-12 {
      right: 100%; }
    
    /* line 45, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-0 {
      left: auto; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-1 {
      left: 8.3333333333%; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-2 {
      left: 16.6666666667%; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-3 {
      left: 25%; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-4 {
      left: 33.3333333333%; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-5 {
      left: 41.6666666667%; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-6 {
      left: 50%; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-7 {
      left: 58.3333333333%; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-8 {
      left: 66.6666666667%; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-9 {
      left: 75%; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-10 {
      left: 83.3333333333%; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-11 {
      left: 91.6666666667%; }
    
    /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-push-12 {
      left: 100%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-0 {
      margin-left: 0%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-1 {
      margin-left: 8.3333333333%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-2 {
      margin-left: 16.6666666667%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-3 {
      margin-left: 25%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-4 {
      margin-left: 33.3333333333%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-5 {
      margin-left: 41.6666666667%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-6 {
      margin-left: 50%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-7 {
      margin-left: 58.3333333333%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-8 {
      margin-left: 66.6666666667%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-9 {
      margin-left: 75%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-10 {
      margin-left: 83.3333333333%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-11 {
      margin-left: 91.6666666667%; }
    
    /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
    .col-xs-offset-12 {
      margin-left: 100%; }
    
    @media (min-width: 768px) {
      /* line 27, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        float: left; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-1 {
        width: 8.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-2 {
        width: 16.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-3 {
        width: 25%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-4 {
        width: 33.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-5 {
        width: 41.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-6 {
        width: 50%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-7 {
        width: 58.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-8 {
        width: 66.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-9 {
        width: 75%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-10 {
        width: 83.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-11 {
        width: 91.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-12 {
        width: 100%; }
    
      /* line 55, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-0 {
        right: auto; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-1 {
        right: 8.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-2 {
        right: 16.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-3 {
        right: 25%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-4 {
        right: 33.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-5 {
        right: 41.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-6 {
        right: 50%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-7 {
        right: 58.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-8 {
        right: 66.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-9 {
        right: 75%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-10 {
        right: 83.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-11 {
        right: 91.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-pull-12 {
        right: 100%; }
    
      /* line 45, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-0 {
        left: auto; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-1 {
        left: 8.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-2 {
        left: 16.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-3 {
        left: 25%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-4 {
        left: 33.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-5 {
        left: 41.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-6 {
        left: 50%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-7 {
        left: 58.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-8 {
        left: 66.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-9 {
        left: 75%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-10 {
        left: 83.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-11 {
        left: 91.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-push-12 {
        left: 100%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-0 {
        margin-left: 0%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-1 {
        margin-left: 8.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-2 {
        margin-left: 16.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-3 {
        margin-left: 25%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-4 {
        margin-left: 33.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-5 {
        margin-left: 41.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-6 {
        margin-left: 50%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-7 {
        margin-left: 58.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-8 {
        margin-left: 66.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-9 {
        margin-left: 75%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-10 {
        margin-left: 83.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-11 {
        margin-left: 91.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-sm-offset-12 {
        margin-left: 100%; } }
    @media (min-width: 992px) {
      /* line 27, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-1 {
        width: 8.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-2 {
        width: 16.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-3 {
        width: 25%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-4 {
        width: 33.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-5 {
        width: 41.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-6 {
        width: 50%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-7 {
        width: 58.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-8 {
        width: 66.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-9 {
        width: 75%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-10 {
        width: 83.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-11 {
        width: 91.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-12 {
        width: 100%; }
    
      /* line 55, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-0 {
        right: auto; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-1 {
        right: 8.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-2 {
        right: 16.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-3 {
        right: 25%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-4 {
        right: 33.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-5 {
        right: 41.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-6 {
        right: 50%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-7 {
        right: 58.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-8 {
        right: 66.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-9 {
        right: 75%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-10 {
        right: 83.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-11 {
        right: 91.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-pull-12 {
        right: 100%; }
    
      /* line 45, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-0 {
        left: auto; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-1 {
        left: 8.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-2 {
        left: 16.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-3 {
        left: 25%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-4 {
        left: 33.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-5 {
        left: 41.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-6 {
        left: 50%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-7 {
        left: 58.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-8 {
        left: 66.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-9 {
        left: 75%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-10 {
        left: 83.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-11 {
        left: 91.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-push-12 {
        left: 100%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-0 {
        margin-left: 0%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-1 {
        margin-left: 8.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-2 {
        margin-left: 16.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-3 {
        margin-left: 25%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-4 {
        margin-left: 33.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-5 {
        margin-left: 41.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-6 {
        margin-left: 50%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-7 {
        margin-left: 58.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-8 {
        margin-left: 66.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-9 {
        margin-left: 75%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-10 {
        margin-left: 83.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-11 {
        margin-left: 91.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-md-offset-12 {
        margin-left: 100%; } }
    @media (min-width: 1200px) {
      /* line 27, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
        float: left; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-1 {
        width: 8.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-2 {
        width: 16.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-3 {
        width: 25%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-4 {
        width: 33.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-5 {
        width: 41.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-6 {
        width: 50%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-7 {
        width: 58.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-8 {
        width: 66.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-9 {
        width: 75%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-10 {
        width: 83.3333333333%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-11 {
        width: 91.6666666667%; }
    
      /* line 35, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-12 {
        width: 100%; }
    
      /* line 55, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-0 {
        right: auto; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-1 {
        right: 8.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-2 {
        right: 16.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-3 {
        right: 25%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-4 {
        right: 33.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-5 {
        right: 41.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-6 {
        right: 50%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-7 {
        right: 58.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-8 {
        right: 66.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-9 {
        right: 75%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-10 {
        right: 83.3333333333%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-11 {
        right: 91.6666666667%; }
    
      /* line 50, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-pull-12 {
        right: 100%; }
    
      /* line 45, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-0 {
        left: auto; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-1 {
        left: 8.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-2 {
        left: 16.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-3 {
        left: 25%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-4 {
        left: 33.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-5 {
        left: 41.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-6 {
        left: 50%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-7 {
        left: 58.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-8 {
        left: 66.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-9 {
        left: 75%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-10 {
        left: 83.3333333333%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-11 {
        left: 91.6666666667%; }
    
      /* line 40, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-push-12 {
        left: 100%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-0 {
        margin-left: 0%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-1 {
        margin-left: 8.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-2 {
        margin-left: 16.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-3 {
        margin-left: 25%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-4 {
        margin-left: 33.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-5 {
        margin-left: 41.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-6 {
        margin-left: 50%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-7 {
        margin-left: 58.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-8 {
        margin-left: 66.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-9 {
        margin-left: 75%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-10 {
        margin-left: 83.3333333333%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-11 {
        margin-left: 91.6666666667%; }
    
      /* line 60, airtel/assets/scss/standard/mixins/_grid-framework.scss */
      .col-lg-offset-12 {
        margin-left: 100%; } }

        /* line 294, airtel/assets/scss/standard/_type.scss */
.font-size-inherit {
  font-size: inherit !important; }

/* line 295, airtel/assets/scss/standard/_type.scss */
.font-size-10, .fs-10 {
  font-size: 10px !important; }

/* line 296, airtel/assets/scss/standard/_type.scss */
.font-size-11, .fs-11 {
  font-size: 11px !important; }

/* line 297, airtel/assets/scss/standard/_type.scss */
.font-size-12, .fs-12 {
  font-size: 12px !important; }

/* line 298, airtel/assets/scss/standard/_type.scss */
.font-size-14, .fs-14 {
  font-size: 14px !important;
  line-height: 17px;
  letter-spacing: 1.2px; }

/* line 299, airtel/assets/scss/standard/_type.scss */
.font-size-16, .fs-16 {
  font-size: 16px !important; }

/* line 300, airtel/assets/scss/standard/_type.scss */
.font-size-20, .fs-20 {
  font-size: 20px !important;
  line-height: 24px; }

/* line 301, airtel/assets/scss/standard/_type.scss */
.font-size-24, .fs-24 {
  font-size: 24px !important; }

/* line 302, airtel/assets/scss/standard/_type.scss */
.font-size-26, .fs-26 {
  font-size: 26px !important; }

/* line 303, airtel/assets/scss/standard/_type.scss */
.font-size-30, .fs-30 {
  font-size: 30px !important; }

/* line 304, airtel/assets/scss/standard/_type.scss */
.font-size-36, .fs-36 {
  font-size: 36px !important; }

/* line 305, airtel/assets/scss/standard/_type.scss */
.font-size-40, .fs-40 {
  font-size: 40px !important; }

/* line 306, airtel/assets/scss/standard/_type.scss */
.font-size-50, .fs-50 {
  font-size: 50px !important; }

/* line 307, airtel/assets/scss/standard/_type.scss */
.font-size-60, .fs-60 {
  font-size: 60px !important; }

/* line 308, airtel/assets/scss/standard/_type.scss */
.font-size-70, .fs-70 {
  font-size: 70px !important;
  line-height: 72px !important; }

/* line 309, airtel/assets/scss/standard/_type.scss */
.font-light {
  font-weight: 100 !important; }

  .box-div { 
    border-top: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;
    background: #f2f2f2;
    border-right: 1px solid #cdcdcd;
	padding: 20px 0;
	border-left:3px solid #cdcdcd;
	position:relative;
	

}
.box-div .close{
	position: absolute;
    top: -10px;
    right: -10px;
    font-size: 20px;
    color: #fff;
    background: red;
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 0px;
    box-shadow: 2px 2px 2px #ccc;
    border-radius: 10px;
    opacity: 1;
    padding: 0px;
}
.box-div .name{
	margin-left:0px!important;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

  /* line 377, airtel/assets/scss/standard/_normalize.scss */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }


  /* line 10, airtel/assets/scss/standard/_forms.scss */
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

 /* Adding a dot*/
  .dot {
    height: 12px;
    width: 12px;
    background-color: rgb(157, 160, 164);
    border-radius: 50%;
    display: inline-block;
  }
  .bg-red {
    background-color: rgb(253, 0, 0);
    
  }
  .bg-bright-green {
    background-color: rgb(65, 210, 120);
  }

  .bg-yellow {
    background-color: rgb(204, 220, 106);
  }

  .bg-gray {
    background-color: rgb(131, 132, 133);
  }
  .bg-green {
    background-color: rgb(4, 126, 247);
  }

  .d-flex {
    display: flex;
  }

  .card-border {
    border-bottom: 1px solid white;
  }
 